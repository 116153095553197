.characterStyles {
  font-family: 'Roboto';
  font-size: 36px;
  background-color: transparent;
  color: #4a606a;
  border-bottom: 1px solid #979797;
  border-top: none;
  border-right: none;
  border-left: none;
}

.inactiveCharacterStyles {
  background-color: #e8f1f3;
  color: #69696959 !important;
  border: 1px solid #8cbac7;
  border-radius: 10px !important;
}

.selectedCharacterStyles {
  background-color: #e8f1f3;
  color: #4a606a;
  border: 1px solid #1b7690;
  border-radius: 10px !important;
  outline: 2px solid #8cbac7;
}

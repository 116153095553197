.tippy-box {
  background: white;
  border: 1.5px solid #1b7690;
  border-radius: 10px;
  color: #333;
  box-shadow: 0 3px 14px -0.5px rgba(0, 0, 0, 0.5);
  padding: 10px;

  .tippy-arrow {
    transform-style: preserve-3d;

    &::after {
      content: '';
      position: absolute;
      left: -8px;
      transform: translateZ(-1px);
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
    }
  }
}

.tippy-box[data-theme~='light-no-border'] {
  border: transparent;
}

.tippy-box[data-theme~='light-nickels-theme'] > .tippy-arrow:after,
.tippy-box[data-theme~='light-nickels-theme'] > .tippy-svg-arrow:after {
  content: '';
  position: absolute;
  z-index: -1;
}

.tippy-box[data-theme~='light-nickels-theme'] > .tippy-arrow:after {
  border-color: transparent;
  border-style: solid;
}

.tippy-box[data-theme~='light-nickels-theme'] > .tippy-arrow:after,
.tippy-box[data-theme~='light-nickels-theme'] > .tippy-svg-arrow:after {
  content: '';
  position: absolute;
  z-index: -1;
}

.tippy-box[data-theme~='light-nickels-theme'][data-placement^='right'] > .tippy-arrow:after {
  border-width: 7px 7px 7px 0;
  right: 17px;
  top: 1px;
  border-right-color: #1b7690;
}

.tippy-arrow:before {
  content: '';
  position: absolute;
  border-color: transparent;
  border-style: solid;
}

.tippy-box[data-placement^='right'] > .tippy-arrow:before {
  left: -6px;
  border-width: 8px 8px 8px 0;
  border-right-color: initial;
  transform-origin: center right;
  color: white;
}

.tippy-box[data-theme~='light-nickels-theme'][data-placement^='right'] > .tippy-arrow:before {
  border-right-color: #fff;
  right: 16px;
}
